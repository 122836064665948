"use strict";

import { useRuntimeConfig } from "#app";
import {
  Category,
  DegreeSeekingProgram,
} from "~/server/resolvers/resolvers-types";
import {
  AsuTuitionApiCostObject,
  AsuTuitionApiResponseData,
  AsuTuitionApiResponseDataFeeBreakdown,
  FormattedProgram,
  FormattedProgramCostCalc,
  TermCreditHourCostData,
} from "~/types/types";
import InterestAreaRfiService from "./InterestAreaRfiService";
import { LABEL_FALL_TERM } from "./constants.js";

// internal functions

/**
 * @param {string | number} academicYear - year in string form example: "2024"
 * @param {AsuTuitionApiResponseData} costData
 * @param {"spring"| "fall"} term
 * @param {string | number} creditHour
 */
function generateTermCreditHourCostData(
  academicYear: string | number,
  costData: AsuTuitionApiResponseData,
  term: keyof Omit<AsuTuitionApiResponseData["breakdown"], "summer">,
  creditHour: keyof Omit<AsuTuitionApiResponseDataFeeBreakdown, "descr">
) {
  const parsedYear =
    typeof academicYear == "string" ? parseInt(academicYear) : academicYear;
  const yearCaption = term === LABEL_FALL_TERM ? parsedYear - 1 : parsedYear;
  const data: TermCreditHourCostData = {
    id: `${term}-credit-hour-data`,
    title: `${term} ${yearCaption}`,
    amount: `$${costData.breakdown[term].total[creditHour]}`,
    items: [],
  };

  for (const prop in costData["breakdown"][term]) {
    data.items.push({
      title: costData.breakdown[term][prop]["descr"],
      amount: `$${costData["breakdown"][term][prop][creditHour]}`,
    });
  }

  return data;
}

// export functions
function calculateAcademicYearTotal(
  costData: AsuTuitionApiResponseData,
  creditHour: keyof Omit<AsuTuitionApiResponseDataFeeBreakdown, "descr">
) {
  let total = 0;
  let fallTotal: number;
  let springTotal: number;

  typeof costData["breakdown"]["fall"]["total"][creditHour] == "number"
    ? (fallTotal = costData["breakdown"]["fall"]["total"][creditHour] as number)
    : (fallTotal = parseInt(
        costData["breakdown"]["fall"]["total"][creditHour] as string
      ));
  typeof costData["breakdown"]["spring"]["total"][creditHour] == "number"
    ? (springTotal = costData["breakdown"]["spring"]["total"][
        creditHour
      ] as number)
    : (springTotal = parseInt(
        costData["breakdown"]["spring"]["total"][creditHour] as string
      ));

  total = fallTotal + springTotal;

  return total;
}

function generateResidencyCostData(
  academicYear: string | number,
  costData: AsuTuitionApiResponseData,
  creditHour: keyof Omit<AsuTuitionApiResponseDataFeeBreakdown, "descr">
) {
  const residencyCostData: {
    [id: string | "fall" | "spring"]: TermCreditHourCostData;
  } = {};

  residencyCostData["fall"] = generateTermCreditHourCostData(
    academicYear,
    costData,
    "fall",
    creditHour
  );
  residencyCostData["spring"] = generateTermCreditHourCostData(
    academicYear,
    costData,
    "spring",
    creditHour
  );

  return residencyCostData;
}

const generateCostUrl = (
  residentType: "res" | "nores",
  year: string,
  acadCareer: "UGRD" | "GRAD",
  programCode: string,
  feeCode: string
) => {
  const config = useRuntimeConfig();
  const costApiRawUrl = `${config.public.asuoApiBaseUrl}${config.public.asuoApiCostInfoPath}?honors=0&admit_term=&include_summer=0&campus=ONLNE&acad_year=${year}&acad_prog=${programCode}&residency=${residentType}&acad_career=${acadCareer}`;
  return feeCode !== ""
    ? costApiRawUrl + `&program_fee=${feeCode}`
    : costApiRawUrl;
};

function populateCostObject(
  item: FormattedProgramCostCalc,
  residentType: "Arizona resident" | "Nonresident",
  year: string
) {
  // populate cost object
  let selectedCostData: AsuTuitionApiCostObject = {
    programTitle: item.title,
    override: item.costOverride,
    academicYear: year,
    amount: item.amount ? item.amount : "",
    detailPage: item.detailPage,
    category: item.category,
    costUrl: "",
  };
  const resCode = residentType === "Arizona resident" ? "res" : "nores";
  const careerType =
    selectedCostData.category === "Undergraduate" ? "UGRD" : "GRAD";
  const feeCode =
    resCode === "res" ? item.programFeeCodeRes : item.programFeeCodeNores;

  if (!selectedCostData.override) {
    selectedCostData.costUrl = generateCostUrl(
      resCode,
      year,
      careerType,
      item.programCode,
      feeCode
    );
  }

  return selectedCostData;
}

function generateFormattedProgramCollection(programs: DegreeSeekingProgram[]) {
  const result: FormattedProgramCostCalc[] = [];

  programs.forEach((program) => {
    result.push({
      id: program.id,
      code: program.code!,
      title: program.title!,
      category: program.category?.title!,
      costOverride: program.costOverride!,
      costAcademicYear: program.costAcademicYear!,
      amount: program.costCollection!
        ? program.costCollection!.amount!
        : undefined,
      detailPage: program.detailPage!,
      programCode: program.programCode!,
      programFeeCodeRes: program.resProgramFeeCode!,
      programFeeCodeNores: program.noresProgramFeeCode!,
    });
  });

  return result;
}

function getAllFormattedCostStudentCategories(categoryEdges: Category[]) {
  return categoryEdges.map((item) => item.title!);
}

function getProgramOptions(programs: FormattedProgramCostCalc[]) {
  const options: { value: string; text: string }[] = [];
  programs.forEach((item) => {
    options.push({
      value: item.id,
      text: item.title,
    });
  });

  return options;
}

function getDegreeTypeOptions(degreeTypes: string[]) {
  const options: Array<{ value: string | null; text: string }> = [];

  options.push({
    value: null,
    text: "Select a degree type",
  });

  const degreeOrder = [
    "Undergraduate (Degree)",
    "Graduate",
    "Certificates",
    "Undergraduate (Nondegree)",
  ];

  const mappedDegreeTypes = degreeTypes.map((type) => {
    if (type === "Undergraduate") {
      return { value: "Undergraduate", text: "Undergraduate (Degree)" };
    }
    return { value: type, text: type };
  });

  const orderedDegreeTypes = [
    ...degreeOrder.filter((type) =>
      mappedDegreeTypes.some((item) => item.text === type)
    ),
    ...mappedDegreeTypes.filter((item) => !degreeOrder.includes(item.text)),
  ];

  orderedDegreeTypes.forEach((text) => {
    const matchingItem = mappedDegreeTypes.find((item) => item.text === text);
    if (matchingItem) {
      options.push({
        value: matchingItem.value,
        text: matchingItem.text,
      });
    }
  });

  return options;
}

function generateCategoryProgramsMap(
  categories: string[],
  programs: FormattedProgram[]
) {
  return InterestAreaRfiService.generateCategoryProgramsMap(
    categories,
    programs
  );
}

export default {
  generateResidencyCostData: generateResidencyCostData,
  calculateAcademicYearTotal: calculateAcademicYearTotal,
  getProgramOptions: getProgramOptions,
  populateCostObject: populateCostObject,
  generateFormattedProgramCollection: generateFormattedProgramCollection,
  getDegreeTypeOptions: getDegreeTypeOptions,
  generateCategoryProgramsMap: generateCategoryProgramsMap,
  getAllFormattedCostStudentCategories: getAllFormattedCostStudentCategories,
};
